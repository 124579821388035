<template>
  <div class="nukis">
    <!--Filters-->
    <b-card no-body>
      <b-card-header class="pb-50">
        <b-row no-gutters class="w-100">
          <b-col>
            <h5>{{ msg("Filters") }}</h5>
          </b-col>
          <b-col>
            <div class="d-flex justify-content-end">
              <b-link style="color: inherit" @click="refresh()">
                <feather-icon icon="RefreshCcwIcon" size="16"/>
              </b-link>
            </div>
          </b-col>
        </b-row>
      </b-card-header>
      <b-card-body>
        <b-row>
          <!-- Name -->
          <b-col cols="12" md="4" class="mb-md-0 mb-2">
            <label>{{ msg("Name") }}</label>
            <b-form-input
                v-model="filter.like.name"
                class="d-inline-block mr-1"
            />
          </b-col>
          <!-- Description-->
          <b-col cols="12" md="4" class="mb-md-0 mb-2">
            <label>{{ msg("SmartLockId") }}</label>
            <b-form-input
                v-model="filter.like.smartLockId"
                class="d-inline-block mr-1"
            />
          </b-col>
          <!-- Type-->
          <b-col cols="12" md="4" class="mb-md-0 mb-2">
            <label>{{ msg("Address") }}</label>
            <b-form-input
                v-model="filter.like.address"
                class="d-inline-block mr-1"
            />
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
    <b-card no-body>
      <div class="card-header" style="overflow: hidden">
        <span class="card-title m-0">{{ msg('Nuki smartlocks') }}</span>
        <b-col>
          <div class="d-flex justify-content-end">
            <b-link style="color: inherit" @click="refresh()">
              <feather-icon icon="RefreshCcwIcon" size="16"/>
            </b-link>
          </div>
        </b-col>
      </div>
      <!--Card Content-->
      <b-card-body>
        <div class="">
          <!-- Table Top -->
          <b-row no-gutters class="mb-1">
            <!-- Per Page -->
            <b-col cols="2" md="2" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
              <label>{{ msg('Show') }}</label>
              <v-select
                  v-model="page.amount"
                  @option:selected="refresh"

                  :dir="isRTL ? 'rtl' : 'ltr'"
                  :options="[10, 25, 50, 100]"
                  :clearable="false"
                  class="per-page-selector d-inline-block mx-50"
              />
              <label>{{ msg('entries') }}</label>
            </b-col>

            <!-- Search -->
            <b-col cols="6" md="6">
              <div class="d-flex align-items-center justify-content-end">
                <b-form-input
                    v-model="filter.search"
                    class="d-inline-block"
                    :placeholder="msg('Search')"
                />
                <span class="mr-1"></span>
<!--                <div class="mr-1">
                  <b-button variant="primary" @click="onNukiSelect(0)">
                    <span class="text-nowrap">{{ msg('New Smartlock') }}</span>
                  </b-button>
                </div>-->
              </div>
            </b-col>
          </b-row>
        </div>
        <!--Table-->
        <b-table
            ref="nukiSmartlock-table"
            striped
            hover
            responsive
            v-bind:items="smartlocks"
            v-bind:fields="fields"
            :busy="loading"
            @sort-changed="onSort"
            @row-clicked="onRowClicked"
        >
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle mx-1"></b-spinner>
              <strong>{{ msg('Loading') }}...</strong>
            </div>
          </template>
          <template #cell(images)="data">
            <b-img
                v-bind=" { blank: (data.value == null || data.value[0] == null), blankColor: '#777', width: 50, height: 50,src:(data.value != null && data.value[0] != null ? getOrganizationWebSite +  data.value[0] : null)  }"
                blank-color="#475F7B"
                rounded
                alt="Rounded image"
                class="d-inline-block m-1"
            />
          </template>
        </b-table>

        <!--Table Paginator-->
        <div class="text-center w-100">
          <div class="d-inline-block">
            <b-pagination
                v-model="page.currentPage"
                :total-rows="page.total"
                :per-page="page.amount"
                first-number
                last-number
                @input="refresh"
            />
          </div>
          <div class="d-inline-block px-2"><strong>{{ msg('Total') }}</strong> {{ page.total }}</div>
        </div>
      </b-card-body>
    </b-card>
    <b-modal no-fade v-model="dialogs.nuki" size="lg" ref="nuki-modal" id="nuki-modal" :hide-footer="true"
             no-close-on-backdrop centered>
      <nuki-panel v-if="dialogs.nuki" ref="nukiPanel" @submitted="onSubmitted"/>
      <div class="text-center" style="justify-content:center">
        <b-button variant="outline-primary" class="btn-dialog" @click="submitNuki">
          {{ msg('Save') }}
        </b-button>
      </div>
    </b-modal>

    <smartLock-modal v-bind:dialog.sync="dialogs.nukiOperations" v-bind:id="selected"/>

  </div>

</template>

<script>
import {mapActions, mapGetters} from "vuex";
import PageTitle from "@/@core/components/page-title/PageTitle";
import nukiPanel from "@/components/panels/nuki/nukiPanel";
import smartLockModal from "@/components/modals/nukiOperationsModal"

export default {
  name: "Nuki",
  components: { nukiPanel,smartLockModal},
  data() {
    return {
      nukis: [],
      saving: true,
      loaded: false,
      smartlocks: [],
      error: '',
      selected:"",
      fields: [],
      tableSort: {},
      dialogs: {nuki: false,nukiOperations:false},
      filter: {search: '', like: {name: "", shortDescription: ""}, eq: {type: ""}, lte: {}, gte: {}},
      sort: {
        by: 'name',
        desc: false,
        direction: 'asc'
      },
      page: {
        amount: 10,
        currentPage: 1,
        total: 0
      },
      //Listeners
      listeners: {
        refresh: () => {
        }
      }


    }
  },
  watch: {
    filter: {
      deep: true,
      handler() {
        this.refresh()
      }
    }
  },
  computed: {
    ...mapGetters('translator', ['msg', 'currentFormattedLang']),
    allNukis() {
      return this.nukis;
    }
  },
  created() {
    let $this = this;
    this.setFields();
    this.refresh();
    this.$root.$on('crm::branch-updated', (this.listeners.refresh = () => {
      $this.refresh();
    }));
    this.$root.$on('crm::smartLock::update', this.listeners.refresh)

  },
  beforeDestroy() {
    this.$root.$off('crm::branch-updated', this.listeners.refresh)
    this.$root.$on('crm::smartLock::update', this.listeners.refresh)

  },
  methods: {
    ...mapActions('nuki', ['getAllNukis']),
    setFields() {
      let $this = this
      this.fields = [

        {
          key: 'name',
          label: $this.msg('Address'),
          sortable: true
        },
        {
          key: 'smartLockId',
          label: $this.msg('SmartLockId'),
          sortable: true
        },
        {
          key: 'code',
          label: $this.msg('Code'),
          sortable: false
        }
      ]
    },
    refresh() {
      let $this = this
      this.loading = true;
      this.getAllNukis({
        page: $this.page.currentPage,
        amount: $this.page.amount,
        filter: $this.filter,
        sortcolumn: $this.sort.by,
        sortby: $this.sort.direction,
      }).then(this.onNukisRetrieve, (error) => $this.error = error)

    },
    onNukisRetrieve(data) {
      this.page = {
        amount: data['Amount'],
        total: data['Total'],
        currentPage: data['Page']
      };
      this.sort = {
        by: data["sortColumn"],
        direction: data["sortOrder"]
      }
      this.smartlocks = data["Nukis"];
      this.loading = false;
    },
    reloadNukis() {
      let $this = this;
      this.getAllNukis().then(() => {
        $this.loaded = true;
      }, (error) => $this.error = error);
    },

    onNukiSelect(id) {
      this.dialogs.nuki = true;
    },
    filterUpdate() {
      this.selectedRows = [];
      this.refresh({filter: this.filter})

    },
    onSort(data) {
      this.sort.by = data.sortBy;
      this.sort.direction = data.sortDesc ? "desc" : "asc";
      this.refresh();
    },
    sortStatus(col) {
      if (this.tableSort.sortcolumn !== col)
        return "none";
      return this.tableSort.sortby === 'asc' ? 'ascending' : 'descending'
    },
    submitNuki() {
      this.$root.$emit('crm::submitNuki')
    },
    closeDialog() {
      let $this = this;
      $this.dialogs.nuki = false;
      setTimeout(() => $this.selected = null, 500);
    }
    ,

    onSubmitted(server) {
      if (server.id != null)
        this.selected = server.id;
      this.refresh()
      this.closeDialog()
    },
    onRowClicked(smartlock){
      this.selected=smartlock.id;
      this.dialogs.nukiOperations = true;

    }
  }
}
</script>

<style>

</style>
