<template>
  <div class="nukiPanel">
    <b-tabs class="tabs-title">
      <b-tab :title="msg('Nuki details')" active>
        <div class="mt-2" v-if="loaded">

          <validation-observer ref="refFormObserver">
            <!-- Header: Personal Info -->
            <div class="d-flex">
              <feather-icon icon="UserIcon" size="19"/>
              <h4 class="mb-0 ml-50">
                {{ msg('Nuki Information') }}
              </h4>
            </div>


            <b-row class="mt-1">
              <b-col cols="12" md="6" lg="4">
                <b-form-group :label="msg('Nuki SmartLockId')" label-for="token">
                  <validation-provider #default="{ errors }" :name="msg('Nuki SmartLockId')" rules="required">
                    <b-form-input type="number" id="SmartLockId" v-model="nuki.smartLockId"/>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col cols="12" md="6" lg="4">
                <b-form-group :label="msg('Nuki name')" label-for="nuki-name">
                  <b-form-input id="nuki-name" v-model="nuki.name"/>
                </b-form-group>
              </b-col>
              <b-col cols="12" md="6" lg="4">
                <b-form-group :label="msg('Nuki token')" label-for="nuki-token">
                  <b-form-input id="nuki-token" v-model="nuki.token"/>
                </b-form-group>
              </b-col>
              <b-col cols="12" md="6" lg="6">
                <b-form-group :label="msg('Nuki serialId (back of the device)')" label-for="nuki-nukiId">
                  <b-form-input id="nuki-nukiId" v-model="nuki.nukiId"/>
                </b-form-group>
              </b-col>


              <b-col cols="12" md="6" lg="6">
                <b-form-group :label="msg('Branch')" label-for="nuki-branch">
                  <v-select
                      :dir="isRTL ? 'rtl' : 'ltr'"
                      v-model="nuki.buid"
                      :options="allBranches"
                      class="w-100"
                      label="name"
                      :reduce="(branch) => branch.id"
                      :clearable="false"
                  />
                </b-form-group>
              </b-col>

            </b-row>

          </validation-observer>
        </div>
        <!----------------------------------------------------------------------->
        <div v-else-if="this.error != null">
          <b-alert show variant="danger"><i class="fas fa-exclamation-triangle mr-3"></i>
            <strong>{{ error }}</strong>
          </b-alert>
        </div>
        <div v-else>
          <div style="min-height: 300px;text-align: center">
            <div style="margin-top: 20%">
              <b-spinner label="Spinning"/>
              {{ msg('loading') }}
            </div>
          </div>
        </div>
      </b-tab>

    </b-tabs>
  </div>
</template>

<script>

import {mapActions, mapGetters, mapState} from "vuex";
import minLength from "vuelidate/src/validators/minLength";

const required = (value) => {
  console.log("required ", value);
  return value != null && value > 0
};

export default {
  name: "nukiPanel",
  components: {},
  data() {
    return {
      loaded: false,
      error: null,
      nuki: {
        id: null,
        name: "",
        nukiId: "",
        smartLockId: 0,
        orgid: 1,
        buid: 0,
        token: "",
      },

      //Listeners
      listeners: {
        submit: () => {
        }
      }
    }
  },
  props: {
    id: Number,
  },
  watch: {
    id: function () { // watch it
      this.onLoad();
      this.error = null;
    }
  },
  computed: {
    ...mapGetters('translator', ['msg']),
    ...mapGetters('data', {
      selectedBuIds: 'getSelectedBranchesIds',
      getBranchName: 'getBranchName',
      _getAllBranches: 'getAllBranches',
    }),

    ...mapState({
      data: function (store) {
        return store.data
      },
      user: function (store) {
        return store.user
      },
      branches: function (store) {
        return store.branches;
      },

    }),
    allBranches() {
      return Object.values(this._getAllBranches)
    },
  },

  created() {

    this.onLoad();
    this.$root.$on('crm::submitNuki', (this.listeners.submit = () => {
      this.submit();
    }));
  },
  beforeDestroy() {
    this.$root.$off('crm::submitNuki', this.listeners.submit)
  },
  methods: {
    ...mapActions('nuki', {_register: "createNuki"}),
    onLoad() {
      this.loaded = false;
      if (this.id === 0 || typeof this.id !== "number") {
        this.id = 0;

        this.nuki = {
          id: 0,
          name: "",
          orgid: 1,
          buid: 0,
          alias: "",
        };
        this.loaded = true;
      }
    },

    createNuki() {
      let $this = this;
      this.nuki.id = null;
      this._register(this.nuki).then(
          (id) => {
            $this.$emit("submitted", {status: true, server: id, id})

          },
          (a) => $this.$emit("submitted", {status: false, server: a})
      );
    },

    validate(field) {
      return this.$v.nuki[field] != null && this.$v.nuki[field].$error === true;
    },
    submit(e) {

      if (typeof e === 'object' && typeof e.preventDefault === 'function')
        e.preventDefault();


      if (this.$v.$invalid)
        return;

      return this.createNuki();

    }

  },
  validations: {
    nuki: {
      name: {
        minLength: minLength(8),
      },
      buid: {
        required
      },
    }
  }
}
</script>

<style>

</style>
